<template>
  <div class="container">
    <h1 class="title">Periodos facturación</h1>
    <BillingList :billings="billings" @show-modal="showModalFunc" />
		<ModalForm title='Crear proceso de facturación' :visible="showFormModal" @close-modal-func="closeModalFunc" :formName="'billingForm'" @save="saveBilling" />
  </div>
</template>

<script>
import BillingList from '@/components/billing/billingList.vue'
import ModalForm from '@/components/modalForm.vue'
export default {
  name: 'BillingView',
  components: {
      BillingList,
			ModalForm
  },
  data: () => ({
    billings: [],
		showFormModal: false
  }),
  methods: {
    async getBillings () {
			this.billings = []
      this.$axios
      .get('billing/billingsGet')
      .then(response => {
        this.billings = response.data.billings

      })
      .catch(error => console.log(error))
    },
		closeModalFunc() {
      this.showFormModal = false
    },
		showModalFunc() {
			// console.log('showModalFunc')
      this.showFormModal = true
			// console.log(this.showFormModal)
    },
		async saveBilling (billingObj, convertCurrencies) {
      // console.log(billingObj)
			await this.$axios
			.post('billing/create', { billing: billingObj, convertCurrencies: convertCurrencies })
			.then(response => {
        const result = response.data.result
        const billing = response.data.billing
        if(result === 'OK')
          this.$router.push({ name: "BillingView", params: { id: billing.id }})
			})
			.catch(error => console.log(error))
			this.closeModalFunc()
		}
	},
	mounted(){
		this.getBillings()
	}
}
</script>
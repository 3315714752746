<template>
<section>
  <div class="container">
    <div v-if="billings">
      {{ billings.length > 0? billings.length: 'No existen facturaciones creadas, selecciona el boton agregar para crear la primera facturación' }}
    </div>
    <div class="columns is-multiline">
      <div class="column is-half">
        <div class="box content is-small">
          <a>
            <button class="button is-large" @click.prevent="newEvent()">
              <span>
                <i class="fa fa-plus-circle fa-lg"></i>
              </span>
            </button>
            
          </a>
        </div>
      </div>
      <div class="column is-half" v-for="billing in billings" :key="billing.id">
        <div class="box content is-small">
          <router-link :to="{ name: 'BillingView', params: {id: billing.id}}">
            <article class="post">
              <p class="has-text-dark is-size-4 has-text-weight-semibold is-family-code">{{ billing.year }} / {{ getMonthName(billing.month, billing.year) }}</p>
              <div class="media">
                <div class="media-content">
                  <div class="content">
                    <div class="container2 has-text-centered">
                      <div class="item">
                          <p class="bd-notification is-primary">
                            <a class="circle circle-total is-family-monospace has-text-weight-semibold has-text-dark">${{ formatPrice(billing.total) }}</a><br>
                            <a class="is-family-monospace has-text-dark has-text-weight-semibold">Total</a>
                          </p>
                      </div>
                      <div class="item">
                        <p class="bd-notification is-primary">
                          <a class="circle is-family-monospace has-text-weight-semibold has-text-dark">${{ formatPrice(billing.recaudado) }}</a><br>
                          <a class="is-family-monospace has-text-dark has-text-weight-semibold">Recaudado</a>
                        </p>
                      </div>
                      <div class="item">
                        <p class="bd-notification is-primary">
                          <a class="circle circle-pendiente is-family-monospace has-text-weight-semibold has-text-dark">${{ formatPrice(billing.pendiente) }}</a><br>
                          <a class="is-family-monospace has-text-dark has-text-weight-semibold">Pendiente</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</section>
</template>
<script>
export default {
  props: {
    billings: Array
  },
	data: () => ({ }),
	components: { },
  methods: { 
		newEvent() {
			this.$emit('show-modal')
		},
		getMonthName (month, year) {
      const newDate = new Date(year, month - 1, 1)
      const monthName = newDate.toLocaleString('es-cl', { month: 'long' })
      return monthName.charAt(0).toUpperCase() + monthName.slice(1)
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
	}
}
</script>
<style scoped>
.container2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.item {
  flex-grow: 0;
  
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 10px;
  
  justify-content: center;
  align-items: center;
}

.circle {
  
  width: 85px;
  height: 85px;
  border-radius: 45px;
  border: solid 3px rgb(1, 173, 102);
  background-color: rgb(235, 235, 235);
  margin-right: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.circle-total {
  border: solid 3px rgb(221, 220, 161);
}
.circle-pendiente {
  border: solid 3px rgb(202, 139, 139);
}
</style>